// colors
//$light-purple: #efeaf7;
//$gold: #e3c45f;
//$light-gold: #fbe290;
$gold: #e3c45f;
$light-gold: #e3c45f;
$brand-green: #b5feb9;


$white: white;
$light-gray: #ededed;
$grey: #3e3e3e;
$black: black;
$red: #ff2448;
$green: $gold;
$blue: $gold;

$font-size-base: 17px;

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "font-awesome";

@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500');

@font-face {
  font-family: 'HelveticaNeueLTStd';
  src: url('../fonts/HelveticaNeueLTStd-LtCn.eot');
  src: url('../fonts/HelveticaNeueLTStd-LtCn.woff2') format('woff2'),
       url('../fonts/HelveticaNeueLTStd-LtCn.woff') format('woff'),
       url('../fonts/HelveticaNeueLTStd-LtCn.ttf') format('truetype'),
       url('../fonts/HelveticaNeueLTStd-LtCn.svg#HelveticaNeueLTStd-LtCn') format('svg'),
       url('../fonts/HelveticaNeueLTStd-LtCn.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    }
    @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    font-size: $fs-font-size;
  }
}

$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$lead-font-sizes: (
  null  : 18px,
  small : 19px,
  medium: 20px,
  large : 22px
);
$hero-h1: (
  null  : 32px,
  small : 36px,
  medium: 40px,
  large : 42px
);
$cta-h3: (
  null  : 20px,
  small : 26px,
  medium: 30px,
  767px: 24px,
  large : 34px,
  1200px: 36px,
);
$news-frame-h3: (
  null  : 26px,
  small : 28px,
  medium: 30px,
  large : 32px
);
$main-menu-h3: (
  small : 22px,
  medium: 24px,
  large : 26px
);

@media (max-width: $screen-xs-max) { }
@media (min-width: $screen-sm-min) { }
@media (max-width: $screen-sm-max) { }
@media (min-width: $screen-md-min) { }
@media (max-width: $screen-md-max) { }
@media (min-width: $screen-lg-min) { }


// anchor
.anchor-inherit-color a,
.anchor-inherit-color a:hover {
	color: inherit;
	background-color: inherit;
}

a {
	color: $gold;
}
a:hover, a:focus {
	color: $gold;
}

.show-more-link {
	background-color: inherit;
	
	&.gold {
		
		a {
			letter-spacing: .5px;
			color: $gold;
		}
	}	
}

.active {
	text-decoration: underline;
}

// scaffolding
$body-bg: $black;
$link-color: $blue;


section {
	margin: 45px 0 45px 0;
}

img {
	max-width: 100%;
}

small {
	letter-spacing: .5px;
}

// typography
$font-family-sans-serif: "HelveticaNeueLTStd", Helvetica Neue, Helvetica, Arial, sans-serif;
$font-family-oswald: "Oswald", sans-serif;

body {
	font-family: $font-family-sans-serif;
	-webkit-font-smoothing: antialiased;
	background-image: url('../img/roy-bg.jpg');
	background-repeat: repeat-y;
	background-color: $black;
	color: $white;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-family-oswald !important;
	font-weight: 300;
}

h1 {
	margin-bottom: 30px !important;
	font-weight: 300 !important;

	&.page-title {
		text-transform: uppercase;
	}
}

h1, h2 {
	color: $white;
}

.mobile-margin {
  margin-top: 0;
}

@media (max-width: 767px) {
	  .mobile-margin {
	    margin: -20px 0 20px 0 !important;
	  }
}


// carousel
$carousel-indicator-active-bg: #8e8e8d;
$carousel-indicator-border-color: #8e8e8d;

.carousel-indicators {
	bottom: 0 !important;
	left: inherit !important;
	right: 10px;
	width: inherit !important;
}

.byline {
	text-decoration: none;
	margin: 10px;
}

.lead {
	@include font-size($lead-font-sizes);
	margin-top: 8px;
	font-weight: 400;
}

.hero-frame {
	border: 1px solid $grey;
	padding: 30px 20px 10px;

	@media (min-width: $screen-sm-min) { 
		padding: 30px 35px 10px;		
	}

	h1 {
		@include font-size($hero-h1);
	}
}

// components
$border-radius-base: 0;

.top {
	display: block;
	border-bottom: 1px solid $black;
	font-size: 12px;
}

.pad {
	padding: 20px 10px;
	h3 {
		margin-top: 0;
		line-height: 1.3;
	}
	a {
		color: inherit !important;
		text-decoration: none;
	}

	@media (max-width: 767px) {
		padding: 20px 0;
	}
}

.post-edit-link {
	margin: 10px 0 40px;
	display: inline-block;
}

// icons
.icon-arrow {
	width: 30px;
	padding-top: 10px;
}

.icon-flag {
	width: 14px;
	margin: -2px 6px 0;
}

.icon-share {
	width: 16px;
	height: 16px;
	//filter: grayscale(100%);
	&:hover {
		//filter: grayscale(0%);
	}
}

// top menu
.top-menu {
	padding: 10px;
	font-size: 12px;
}

// main menu
.main-menu, .mobile-menu {
	margin: 20px 0;
	h3 {
		margin: 40px 0 20px;
		font-family: $font-family-oswald !important;
		@include font-size($main-menu-h3);
		font-weight: 400;
		color: $light-gold;
		text-transform: uppercase;
	}
	li > a {
		padding: 7px 0;
		display: inline-block;
	}
}

.main-menu {
	position: relative;
}

.main-menu-bot {
	display: none;
	position: absolute;
	background: rgba(0, 0, 0, 0.9);
	left: 0;
	right: 0;
	padding-bottom: 20px;
	z-index: 999;
	border-bottom: 1px solid $light-gold;
	
	li {
		font-family: $font-family-oswald !important;
		font-weight: 400;
		color: $light-gold;
		text-transform: uppercase;	
	}
}

.logo {
	max-width: 180px;
	//max-width: 256px;
	//margin-bottom: 20px;
	//-webkit-filter: invert(1);
	//filter: invert(1);
}

// sub menu
.sub-menu {
	li > a {
		padding: 0 0 14px;
		display: inline-block;
		color: $light-gold;
		font-weight: 400;
		
		&:hover {
			color: $light-gold;
		}
	}
	
}

// mobile menu
.mobile-menu {
	display: none;
}

// backgrounds
.font-white {
	color: $white;
	.top {
		border-color: $white;
	}
}
.font-black {
	color: $black;
	.top {
		border-color: $black;
	}
}

.bg-black {
	background-color: $black;
	@extend .font-white;
}

.bg-red {
	background-color: $red;
	@extend .font-white;
}

.bg-green {
	background-color: $green;
}

.bg-blue {
	background-color: $blue;
	@extend .font-white;
}

.bg-gray {
	background-color: $light-gray;
}

.bg-gold {
	background-color: $gold;
	@extend .font-black;	
}

.bg-image {
	background-size: cover;
	background: $white center;
}

// underline
[class*="line-"] {
	width: 100%;
	transition: 0.3s ease;

	img.second {
		@media (max-width: 767px) {
			margin-top: 30px;
		}
	}
}

.line-red {
	border-bottom: 4px solid $red;
	&:hover {
		background-color: $red;
		color: $white;
	}
}

.line-green {
	border-bottom: 4px solid $green;
	&:hover {
		background-color: $green;
	}
}

.line-blue {
	border-bottom: 4px solid $blue;
	&:hover {
		background-color: $blue;
		color: $white;
	}
}

.line-black {
	border-bottom: 4px solid $black;
}

.line-thin {
	border-width: 1px;
	&:hover {
		text-decoration: none;
	}
}
.line-trans {
	border-width: 0px;
	margin-bottom: 30px;
	&:hover {
		background-color: $black;
		text-decoration: none;
	}
}
.line-gold {
	border-width: 0px;
	margin-bottom: 30px;
	&:hover {
		background-color: $gold;
		text-decoration: none;
	}
}

// featured news
.featured-box {
	position: relative;
	width: 100%;
	height: auto;
	margin-bottom: 30px;
	
	.featured-img {
		display: inline-block;
    width: 100%;
    height: auto;
	}
	.img-pad {
		position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 25px;

		h3, h4 {
			font-family: $font-family-oswald !important;
			font-weight: 400;
			text-transform: uppercase;
		}
    
    h3 {
	    font-size: 28px;
	    margin-top: 10px;
    }
    h4 {
	    font-size: 20px;
	    margin-bottom: 0;
    }
	}
	.cta-pad {
		position: absolute;
    bottom: 0;
    left: 0;
		text-align: center;
		padding: 0 40px;
		
		bottom: 50%;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);

		h3, h4 {
			font-family: $font-family-oswald !important;
			font-weight: 400;
			text-transform: uppercase;
		
		}
    
    h3 {
	   	@include font-size($cta-h3);
	    margin-top: 10px;
	    line-height: 1.4;
    }
    h4 {
	    font-size: 20px;
	    letter-spacing: .5px;
	    margin-bottom: 0;

	   	@media (max-width: $screen-xs-max) {
		   	display: none;
	   	}
    } 

    
    .btn {
	    background-color: $white;
	    font-family: $font-family-oswald !important;
			font-weight: 400;
			text-transform: uppercase;
			color: $black;
			margin-top: 10px;
    }
    
	}
	
}

.news-frame {
	border: 1px solid #3e3e3e;
	position: relative;
	
	.pad {
		padding: 25px 25px 20px;
		
		h3 {
			font-family: $font-family-oswald !important;
			font-weight: 300;
			@include font-size($news-frame-h3);
			margin-bottom: 20px;
			color: $red;
		}
		p {
			@include font-size($lead-font-sizes);
			line-height: 1.4;
		}
	}
	.corner-link {
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 0 -1px -3px 0;
		background-color: $black;
		padding: 14px 0 0 18px;
		z-index: 10;
		
		font-family: $font-family-oswald !important;
		color: $red;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 400;
	}
}

.list-news {
	
	.col-md-6:first-child {
		
		h3 {
			color: $white;
		}
		.corner-link {
			color: $white;
		}
	}
}
.archive-list {
	.col-md-4:nth-child(odd) {
		h3 {
			color: $white;
		}
	}
}

// table
$table-bg: #f1f1f1;
$table-bg-hover: $green;

.calender {
	h2 {
		font-weight: 300;
		color: $light-gold;
	}
}

.table-calendar {
	color: $gold;
	
	th, td {
		font-weight: normal;
		border-top: none !important;
	}
	a.cal-title {
		font-size: 18px;
		line-height: 15px;
	}
}
.table-responsive {
	margin-bottom: 0 !important;
	border: none !important;
}
.table-hover {
	> tbody {
		> tr:hover {
			background-color: $black;
		}
	}
}

// movie archive 
#filters {
	float: left;
	margin-bottom: 30px;
	width: 100%;
	padding-left: 15px;
	
	.select-style {
	  //$select-padding: 10px 0px;
	  //$select-padding-l: $select-padding * 1.4;
	  $select-border: 1px solid #434343;
	  $select-color: $white;
	 
	  $caret-size: 6px;
	  $caret-color: #434343;
	  
	  min-width: 150px;
	  position: relative;
	  display: inline-block;
		margin-right: 20px;
	  
	  &:before {
	    content: "";
	    display: inline-block;
	    position: absolute;
	    top: 50%;
	    margin-top: $caret-size * -0.3;
	    right: 0;
	    width: 0;
	    height: 0;
	    border: $caret-size solid transparent;
	    border-top-color: $caret-color;
	  }
	  select {
		  font-family: $font-family-oswald !important;
			font-weight: 300 !important;
			text-transform: uppercase !important;
		  width: 100%;
	    color:  $select-color;
	    display: inline-block;
	    position: relative;
	    box-shadow: none;
	    appearance: none;
	    padding: 10px 0;
	    border-top: $select-border;
	    border-right: 0;
	    border-bottom: $select-border;
	    border-left: 0;
	    border-radius: 0;
	    background: transparent;
	    &:focus {
	      outline: transparent;
	    }
	    &:-moz-focusring {
	      color: transparent;
	      text-shadow: 0 0 0 #000;
	    }
	  }
	}
}

.movie-item {
	min-height: 398px;
	
	@media (max-width: $screen-xs-max) { }
	@media (min-width: $screen-sm-min) {
		min-height: 340px;
	}
	@media (max-width: $screen-sm-max) { }
	@media (min-width: $screen-md-min) {
		min-height: 360px;
	}
	@media (max-width: $screen-md-max) { }
	@media (min-width: $screen-lg-min) {
		min-height: 398px;
	}

	&:nth-child(even) {
		h4 {
			color: $red;
		}
	}
	
	h4 {
		text-transform: uppercase;
	}
	hr {
		float: left;
		width: 100%;
		border: none;
		height: 1px;
		margin-top: 0;
		margin-bottom: 30px;
	}
	
	.youtube {
    background-color: #000;
    margin-bottom: 30px;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #434343;
	
		img {
	    width: 100%;
	    top: -16.82%;
	    left: 0;
	    opacity: 0.7;
		}
		
		.play-button {
	    width: 90px;
	    height: 60px;
	    background-color: #333;
	    box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
	    z-index: 1;
	    opacity: 0.8;
	    border-radius: 6px;
		}
			
		.play-button:before {
	    content: "";
	    border-style: solid;
	    border-width: 15px 0 15px 26.0px;
	    border-color: transparent transparent transparent #fff;
		}
		
		img, .play-button {
	    cursor: pointer;
		}
		
		img,
		iframe,
		.play-button,
		.play-button:before {
		    position: absolute;
		}
		.play-button,
		.play-button:before {
		  top: 50%;
		  left: 50%;
		  transform: translate3d( -50%, -50%, 0 );
		}
		iframe {
		  height: 100%;
		  width: 100%;
		  top: 0;
		  left: 0;
		}
	}
	
}




// form
input {
	border: none;
}
.input-black {
	
	input, button, button:hover {
		box-shadow: none;
		background-color: transparent;
		border-color: $gold;
		color: $black;
	}
	input.btn, button, button:hover {
		background-color: $gold;
	}
	.form-control {
		padding-left: 0;
	}
	.btn {
		text-transform: uppercase;
		margin: 0 -2px -17px 0;
		font-size: 20px;
		font-weight: 300;
		padding: 0 0 0 10px;
		border-radius: 0;
	}
	.btn-default:hover, .btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle, .btn-default:focus, .btn-default.focus {
		color: inherit;
    background-color: $gold;
    border-color: transparent;
	}
}

.form-control, .form-control:focus {
	border: none !important;
	box-shadow: none !important;
	
	.mce_inline_error {
		background-color: transparent !important;
	}
}

// footer
.footer {
	padding: 20px 10px 30px 10px;
	font-size: 16px;
	margin-top: 100px;
	font-family: $font-family-oswald !important;
	font-weight: 300;
	text-transform: uppercase;

	h4 {
		font-weight: 300;
	}
	
	.fa {
		font-size: 20px;
	}
	.fa-heart {
		color: $gold;
	}
}

// grid
.no-gutter {
	margin-right: 0 !important;
	margin-left: 0 !important;
	[class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}

.row-flex, .row-flex > div[class*='col-'] {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex: 1 1 auto;
}

.row-flex {
	-webkit-flex-flow: row wrap;
	flex-flow: row wrap;
	align-content: flex-start;
	flex: 0;
}

.row-flex > div[class*='col-'] {
	margin: -0.3px;
}



.btn-gold {
	@include button-variant($white, $gold, $gold);
}
.btn-green {
	@include button-variant($black, $brand-green, $brand-green);
	font-weight: bold;
	text-transform: uppercase;
	border-radius: 0;
	padding: 5px 10px 0px 10px;
	letter-spacing: 1px;
}
.color-green {
	color: $brand-green;
}
.vote-box {
	border: 1px solid #3E3E3E;
	padding: 20px;
	margin-bottom: 30px;
	position: relative;
	width: 100%;

	h4 {
		margin: 0 0 0 10px;
	}

	.btn {
		position: absolute;
		right: 30px;
		bottom: 30px;
	}
}
input.bottom-line, input.bottom-line:focus {
	border: none;
	border-bottom: 1px solid #fff !important;
	color: #fff;
	background-color: transparent;
	padding-left: 0;
	border-radius: 0;
}

input:-webkit-autofill {
	-webkit-box-shadow: inset 0 0 0px 9999px white;
}


/* icons font-face */
@font-face {
    font-family: "FiltrifyRegular";
    src: url("../font/filtrify/filtrify-webfont.eot");
    src: url("../font/filtrify/filtrify-webfont.eot?#iefix") format("embedded-opentype"),
         url("../font/filtrify/filtrify-webfont.woff") format("woff"),
         url("../font/filtrify/filtrify-webfont.ttf") format("truetype"),
         url("../font/filtrify/filtrify-webfont.svg#FiltrifyRegular") format("svg");
    font-weight: normal;
    font-style: normal;
}


#mc_embed_signup .button:hover {background-color:#777;}
#mc_embed_signup .nowrap {white-space:nowrap;}

//#mc_embed_signup .mc-field-group {clear:left; position:relative; width:96%; padding-bottom:3%; min-height:50px;}
#mc_embed_signup .size1of2 {clear:none; float:left; display:inline-block; width:46%; margin-right:4%;}
* html #mc_embed_signup .size1of2 {margin-right:2%; /* Fix for IE6 double margins. */}
#mc_embed_signup .mc-field-group label {display:block; margin-bottom:3px;}
//#mc_embed_signup .mc-field-group input {display:block; width:100%; padding:8px 0; text-indent:2%;}
#mc_embed_signup .mc-field-group select {display:inline-block; width:99%; padding:5px 0; margin-bottom:2px;}

#mc_embed_signup .indicates-required {text-align:right; font-size:11px; margin-right:4%;}
#mc_embed_signup .asterisk {color:#e85c41; font-size:150%; font-weight:normal; position:relative; top:5px;}     
#mc_embed_signup .clear {clear:both;}

#mc_embed_signup .mc-field-group.input-group ul {margin:0; padding:5px 0; list-style:none;}
#mc_embed_signup .mc-field-group.input-group ul li {display:block; padding:3px 0; margin:0;}
#mc_embed_signup .mc-field-group.input-group label {display:inline;}
#mc_embed_signup .mc-field-group.input-group input {display:inline; width:auto; border:none;}

#mc_embed_signup div#mce-responses { 
	float:left;
	top:-1.4em;
	padding: 0;
	overflow:hidden;
	width:100%;
	margin: 0;
	clear: both;
}
#mc_embed_signup div.response {
	margin:	.5em 0; 
	padding:	.5em .5em .5em 0; 
	font-weight:300; 
	float:left; 
	top:-1.5em; 
	z-index:1; 
	width:100%;
	text-transform: initial;
}
#mc_embed_signup #mce-error-response {display:none;}
#mc_embed_signup #mce-success-response {
	color:#000000;
	display:none;
	}
#mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}

#mc-embedded-subscribe {
/*
	clear:both; 
	width:auto; 
	display:block; 
	margin:1em 0 1em 5%;
*/
}
#mc_embed_signup #num-subscribers {font-size:1.1em;}
#mc_embed_signup #num-subscribers span {padding:.5em; border:1px solid #ccc; margin-right:.5em; font-weight:bold;}

div.mce_inline_error { 
	position: absolute; 
	top: -20px !important; 
	left: 0 !important; 
	display:inline-block;
	padding: 0 !important;
	margin: 0 !important; 
	//padding:5px 10px; 
	background-color: transparent !important; 
	font-size:13px !important; 
	font-weight: 300 !important;
	text-transform: none !important;  
	color:#000 !important; 
	z-index: 1002 !important;
	}
#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {border:2px solid #e85c41;}


